import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Mentions from "../components/Mentions"

const MentionPage = () => (
  <Layout>
    <SEO title="Mentions" />
    <Mentions />
  </Layout>
)

export default MentionPage
