import React, { Fragment } from "react"
import styled from "@emotion/styled"
import mentions from "../images/mentions.jpg"

const SakesContainer = styled.div`
  height: 100vh;
  display: flex;
  overflow: hidden;

  @media screen and (max-width: 640px) {
    height: auto;
    margin-top: 100px;
    flex-wrap: wrap;
  }
`

const ImageContainer = styled.div`
  width: 40%;
  overflow: hidden;
  position: relative;

  @media screen and (max-width: 640px) {
    height: 50vh;
    width: 100%;
  }

  .bg-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`
const TextContainer = styled.div`
  width: 60%;
  padding: 120px 60px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;

  @media screen and (max-width: 640px) {
    height: auto;
    width: 100%;
    padding: 60px 40px;
  }
`

const MentionTitle = styled.p`
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 40px;
`

const MentionSubTitle = styled.p`
  text-transform: uppercase;
  font-size: 20px;
  margin-bottom: 20px;
`

const MentionText = styled.p`
  line-height: 1.7;
  margin-bottom: 40px;
  font-size: 14px;

  ul {
    list-style-position: outside;
  }

  .emoji {
    font-size: 20px;
  }
`

const Mentions = () => {
  return (
    <Fragment>
      <SakesContainer>
        <ImageContainer>
          <img className="bg-image" src={mentions} alt="Mur de sakés"></img>
        </ImageContainer>
        <TextContainer>
          <MentionTitle>Mentions Légales</MentionTitle>
          <MentionSubTitle>
            NOTRE IDENTITÉ JURIDIQUE : HARA KIRI RAMEN
          </MentionSubTitle>
          <MentionText>
            Hara kiri (nom commercial) est exploité par la société 82 HEO,
            Société par actions simplifiée.
            <br></br>
            Le président est Otto H Nijdam{" "}
            <span className="emoji" role="img" aria-label="emoji" alt="emoji">
              🤵
            </span>{" "}
            et le directeur Général : Egon Nijdam{" "}
            <span className="emoji" role="img" aria-label="emoji" alt="emoji">
              👨‍🍳
            </span>
            <br></br>
            La société 82 HEO a été immatriculée au RCS de paris le 28 aout 2018
            <br></br>
            Le numéro de SIRET est le 841 929 904
          </MentionText>
          <MentionSubTitle>LE SITE INTERNET</MentionSubTitle>
          <MentionText>
            Le site internet harakiriramen.fr est exploité par la société 82 HEO
            pour le restaurant hara kiri ramen.
            <br></br>
            La marque hara kiri ainsi que le logo sont protégés à l’INPI, ils
            sont la propriété de Otto H Nijdam.
            <br></br>
            Toutes les images, les textes et les vidéos utilisés sur ce site
            sont la propriété de la société 82 HEO, exploitante de la marque
            hara kiri ramen.
            <br></br>
            Toute reproduction partielle ou complète des éléments figurant sur
            ce site doit faire l’objet d’une autorisation préalable auprès de la
            société 82 HEO, exploitante de la marque hara kiri ramen.
          </MentionText>
          <MentionSubTitle>NOS CRÉDITS</MentionSubTitle>
          <MentionText>
            Webdéveloppeur : Félix Perroud{" "}
            <span className="emoji" role="img" aria-label="emoji">
              👨‍💻
            </span>
            <br></br>
            Graphiste : Lily Allegra{" "}
            <span className="emoji" role="img" aria-label="emoji">
              👩‍🎨
            </span>
            <br></br>
            Police d'écriture : Open Sans
            <br></br>
            Photos du site : Hara kiri ramen
          </MentionText>
          <MentionSubTitle>NOTRE FOURNISSEUR D’HÉBERGEMENT</MentionSubTitle>
          <MentionText>
            L’hébergement du site Harakiriramen.fr est assuré par 
            <span>
              <a
                target="_blank"
                href="http://netlify.com"
                rel="noopener noreferrer"
              >
                Netlify.com
              </a>
            </span>
            <br></br>
            Siège social : San Francisco, US (HQ)
            <br></br>
            2325 3rd St #215,
            <br></br>
            San Francisco, US.
          </MentionText>
          <MentionSubTitle>UTILISATION DE COOKIES</MentionSubTitle>
          <MentionText>
            La connexion au site Internet www.harakiriramen.fr et la navigation
            sur l’une de ses pages Internet peuvent entraîner l’installation de
            cookies sur votre ordinateur, tablette ou mobile. Les utilisateurs
            sont informés que, lors de l’accès au site, des informations peuvent
            être temporairement conservées en mémoire sur leur disque dur afin
            de faciliter la navigation.
          </MentionText>
          <MentionSubTitle>QU’EST-CE QU’UN COOKIE ?</MentionSubTitle>
          <MentionText>
            Un cookie est un petit fichier qui enregistre les paramètres
            Internet. Il est téléchargé via un navigateur lorsque vous visitez
            pour la première fois un site Web. Lorsque vous retournez sur ce
            site à l’aide du même ordinateur, le navigateur peut vérifier si un
            cookie correspondant est présent et utiliser les données contenues
            dans ce cookie pour les transmettre au site.
          </MentionText>
          <MentionSubTitle>COOKIES UTILISÉS ET LEURS FINALITÉS</MentionSubTitle>
          <MentionText>
            Hara kiri ramen utilise les cookies d’analyse Google Analytics. Les
            informations collectées via les cookies d’analyse Google Analytics
            permettent de mesurer le nombre de visites, le nombre de pages vues,
            l’activité des visiteurs sur le site ainsi que leur fréquence de
            retour afin de mieux adapter le site aux demandes des utilisateurs
            et améliorer l’intérêt et l’ergonomie du site www.harakiriramen.fr.
            Pour une présentation de la politique de sécurité et confidentialité
            des données de Google, nous vous invitons à visitez le site :
            https://support.google.com/analytics/answer/6004245?hl=fr. Pour
            savoir comment désactiver le suivi de Google Analytics, nous vous
            invitons à consulter le site :
            https://tools.google.com/dlpage/gaoptout?hl=fr En continuant
            d’utiliser le site hara kiri Ramen en connaissance des informations
            détaillées ci-dessus, vous acceptez expressément l’utilisation de
            tels cookies.
          </MentionText>
          <MentionSubTitle>CONTRÔLER ET SUPPRIMER LES COOKIES</MentionSubTitle>
          <MentionText>
            Pour contrôler les cookies, la plupart des navigateurs vous
            permettent d’accepter ou de rejeter tous les cookies, de n’accepter
            que certains types de cookies ou vous pose la question chaque fois
            qu’un site souhaite enregistrer un cookie. Il est également facile
            de supprimer les cookies qui ont été enregistrés sur votre
            ordinateur par un navigateur. Il est possible de s’opposer à
            l’installation de cookies en configurant son navigateur Internet de
            la manière suivante :<br></br>
            <br></br>
            <ul>
              <li>
                Pour Safari : Choisir le menu « Edition » puis « Préférences »
                et Cliquer sur l’icône « Sécurité » ; Sélectionner les options
                relatives aux cookies souhaitées.
              </li>
              <li>
                Pour Mozilla Firefox : Choisir le menu « Outil » puis « Options
                » et Cliquer sur l’icône « Vie privée ». Paramétrer le menu
                "Règles de conservation" sur "Utiliser les paramètres
                personnalisés pour l'historique" ; Sélectionner les options
                relatives aux cookies souhaitées.
              </li>
              <li>
                Pour Microsoft Internet Explorer 6, 7 ou 8 (Microsoft) : Choisir
                le menu « Outils », puis « Options Internet » et Cliquer sur
                l’onglet « Confidentialité » ; Sélectionner le niveau souhaité à
                l’aide du curseur.
              </li>
              <li>
                Pour Microsoft Internet Explorer 9 : Choisir le menu « Outils »,
                puis « Options Internet » et Cliquer sur l’onglet «
                Confidentialité » ; sous « Paramètres », déplacer le curseur
                vers le haut pour bloquer tous les cookies ou vers le bas pour
                autoriser tous les cookies, avant de cliquer sur OK.
              </li>
              <li>
                Pour Chrome : Choisir le menu « Paramètres », puis « Afficher
                les paramètres avancés » et cliquer sur le bouton « Paramètres
                de contenu » ; Sélectionner les options relatives aux cookies
                souhaitées.
              </li>
              <li>
                Pour Netscape 6.X et 7. X : Choisir « Edition », « Préférences
                », puis Confidentialité et Sécurité / Cookies.
              </li>
              <li>
                Pour Opéra 6.0 et au-delà : choisir « Fichier », « Préférences
                », puis « Vie Privée ».
              </li>
            </ul>
          </MentionText>
        </TextContainer>
      </SakesContainer>
    </Fragment>
  )
}

export default Mentions
